import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';

import Navbar from 'containers/SaasModern/Navbar';

import Seo from 'components/seo';


const Index = () => {


  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo title="Política de Privacidad. SuLlave.com.co" />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
      
        <div align="center">
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
             <h1>Política de Privacidad</h1>              
          </div>
          <div align="center">
               <br/>
             <h2>Qué datos personales recogemos y por qué los recogemos</h2>              
          </div>
          <div align="justify">
              <p>Cuando visitas nuestra web, recopilamos los datos que se muestran en el navegador, así como la dirección IP y la cadena de agentes de usuario del navegador para ayudar a la detección de spam.</p>
          </div>

          <div align="center">
               <br/>
             <h2>Cookies</h2>              
          </div>

          <div align="justify">
              <p>Cuando accedes a este sitio web, instalamos varias cookies para guardar tu información de acceso y tus opciones de visualización de pantalla. Las cookies de acceso duran hasta treinta días, y las cookies de opciones de pantalla duran un año. Si seleccionas «Recuérdarme», tu acceso perdurará durante dos semanas. Si borras los datos de tu navegador, las cookies de acceso se eliminarán.</p>
          </div>



          <div align="center">
               <br/>
             <h2>Contenido incrustado de otros sitios web</h2>              
          </div>

          <div align="justify">
              <p>Los artículos de este sitio pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras webs se comporta exactamente de la misma manera que si el visitante hubiera visitado la otra web.
                Estas web pueden recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido incrustado, incluido el seguimiento de tu interacción con el contenido incrustado si tienes una cuenta y estás conectado a esa web.</p>
          </div>


          <div align="center">
               <br/>
             <h2>Qué derechos tienes sobre tus datos</h2>              
          </div>

          <div align="justify">
              <p>Si tienes una cuenta o has dejado comentarios en esta web, puedes solicitar recibir un archivo de exportación de los datos personales que tenemos sobre ti, incluyendo cualquier dato que nos hayas proporcionado. También puedes solicitar que eliminemos cualquier dato personal que tengamos sobre ti. Esto no incluye ningún dato que estemos obligados a conservar con fines administrativos, legales o de seguridad.</p>
          </div>

 
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
